<template>
  <div id="mian">
    <div class="header">
      <span @click="toUrl('businessList_shxq', { id: $route.query.id })"
        >基本信息</span
      >
      <span @click="toUrl('addcommercial_jsxx_edit', { id: $route.query.id })"
        >结算信息</span
      >
      <span id="fzcolor">渠道信息</span>
      <span @click="toUrl('addcommercial_pzxx_edit', { id: $route.query.id })"
        >产品信息</span
      >
      <span @click="toUrl('addcommercial_pzxx1', { id: $route.query.id })"
        >配置信息</span
      >
      <span v-if="mchDetail.checkStatus=='PASS'" @click="toUrl('addcommercial_dkxq_edit', { id: $route.query.id })"
        >终端信息</span
      >
    </div>
    <div v-auth="'ACCOUNT:MCH:MCHS:CHANNEL'">
      <div class="desc">
        <h5 class="jsxx">渠道信息</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="padding-left: 10px">
              <p>渠道名称</p>
            </td>
            <td>
              <p>渠道编号</p>
            </td>
            <td>
              <p>支付机构</p>
            </td>
            <td>
              <p>渠道类型</p>
            </td>
            <td>
              <p>结算规则</p>
            </td>
            <td>
              <p>商户号</p>
            </td>
            <td>
              <p>密钥</p>
            </td>
            <td>
              <p>渠道状态</p>
            </td>
            <td v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE'">
              <p>产品信息</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td style="padding-left: 10px">
              <p>{{ v.mchChannelName }}</p>
            </td>
            <td>
              <p>{{ v.mchChannelId }}</p>
            </td>
            <td>
              <p>{{ v.orgName }}</p>
            </td>
            <td>
              <p>
                {{
                  v.channelType == "SINGLE_MERCHANT"
                    ? "单商户模式"
                    : v.channelType == "SERVICE_MERCHANT"
                    ? "服务商模式"
                    : ""
                }}
              </p>
            </td>
            <td>
              <p>
                {{
                  v.settleRule == "AUTO"
                    ? "自动结算"
                    : v.settleRule == "AGENT_PAY"
                    ? "代付"
                    : ""
                }}
              </p>
            </td>
            <td>
              <p>{{ v.mchNo }}</p>
            </td>
            <td style="width: 80px">
              <!-- <p class="hidden1" style="width: 150px;">{{v.mchKey.replace(/[\s\S]/g,'*')}}</p> -->
              <p class="hidden1" style="width: 80px">
                {{ v.mchKey ? "*******" : "" }}
              </p>
            </td>
            <td>
              <p v-show="OFFON_EDIT != 'ACCOUNT:MCH:MCHS:CHANNEL/EDIT'"> {{ v.channelStatus == "ACTIVATED" ? "已开启" : "已关闭" }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/EDIT'">
                <el-switch
                  v-model="v.channelStatus"
                  @change="setSatus(v)"
                  active-color="#13ce66"
                  active-value="ACTIVATED"
                  inactive-value="FROZEN"
                >
                </el-switch>
              </p>
            </td>
            <td v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE'">
              <p>
                <i class="lianjie" @click="configTab(v.mchChannelId,0)">配置</i>
              </p>
            </td>
            <td>
              <p>
                <i
                  v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/EDIT'"
                  class="lianjie"
                  @click="editTab(v)"
                  >编辑</i
                >
                <i
                  v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/DELETE'"
                  class="lianjie"
                  @click="delTab(v.mchChannelId)"
                  >删除</i
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
      <h6
        v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/ADD'"
        class="xzjsxx"
        @click="addTab"
      >
        <span>+</span> 新增渠道信息
      </h6>
      <!-- <div class="btn">
				<el-button @click="toUrl('addcommercial_jsxx_edit',{id:$route.query.id})" class="back">上一步</el-button>
				<el-button @click="toUrl('addcommercial_pzxx_edit',{id:$route.query.id})" class="next" type="primary">下一步</el-button>
			</div> -->
      <el-dialog title="渠道基本信息" :visible.sync="infoShow" width="490px">
        <ul class="overAccount_ul">
          <li class="ul_left">
            <div class="overAccount_ul_title">渠道名称</div>
            <el-input
              v-model="formData.mchChannelName"
              maxlength="128"
              placeholder="同商户名称"
              class="width100"
              disabled
              tabindex="1"
            ></el-input>
            <div class="overAccount_ul_title"><i>*</i>渠道类型</div>
            <el-select
              v-model="formData.channelType"
              placeholder="选择渠道类型"
              class="width100"
              tabindex="3"
            >
              <el-option label="单商户模式" value="SINGLE_MERCHANT"></el-option>
              <el-option
                label="服务商模式"
                value="SERVICE_MERCHANT"
              ></el-option>
            </el-select>
            <div class="overAccount_ul_title"><i>*</i>商户号</div>
            <el-input
              v-model="formData.mchNo"
              maxlength="128"
              placeholder="输入商户号"
              class="width100"
              tabindex="5"
            ></el-input>
            <div class="overAccount_ul_title">商户RSA公钥</div>
            <el-input
              v-model="formData.mchPublicKey"
              maxlength="2048"
              placeholder="输入商户RSA公钥"
              class="width100"
              tabindex="7"
            ></el-input>
            <div class="overAccount_ul_title">机构RSA公钥</div>
            <el-input
              v-model="formData.orgPublicKey"
              maxlength="2048"
              placeholder="输入机构RSA公钥"
              class="width100"
              tabindex="9"
            ></el-input>
          </li>
          <li class="ul-right">
            <div class="overAccount_ul_title"><i>*</i>所属机构</div>
            <el-select
              v-model="formData.orgId"
              placeholder="选择所属机构"
              class="width100"
              tabindex="2"
              :disabled="formData.changeOrg == true ? false : true"
            >
              <el-option
                v-for="(v, i) in organizationsLists"
                :key="i"
                :label="v.orgName"
                :value="v.orgId"
              >
              </el-option>
            </el-select>
            <div class="overAccount_ul_title"><i>*</i>结算规则</div>
            <el-select
              v-model="formData.settleRule"
              placeholder="选择结算规则"
              class="width100"
              tabindex="4"
            >
              <el-option label="自动结算" value="AUTO"></el-option>
              <el-option label="代付" value="AGENT_PAY"></el-option>
            </el-select>
            <div class="overAccount_ul_title"><i>*</i>密钥</div>
            <el-input
              type="password"
              v-model="formData.mchKey"
              maxlength="2048"
              placeholder="输入密钥"
              class="width100"
              tabindex="6"
            ></el-input>
            <div class="overAccount_ul_title">商户RSA私钥</div>
            <el-input
              v-model="formData.mchPrivateKey"
              maxlength="2048"
              placeholder="输入商户RSA私钥"
              class="width100"
              tabindex="8"
            ></el-input>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="infoShow = false">取 消</el-button>
          <el-button class="addBt" :disabled="buttonFlag" @click="sureAdd"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      
      <div class="desc">
        <h5 class="jsxx">电子账簿渠道信息</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="padding-left: 10px">
              <p>渠道名称</p>
            </td>
            <td>
              <p>机构名称</p>
            </td>
            <td>
              <p>商户号</p>
            </td>
            <td>
              <p>产品信息</p> 
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-if="AccBooChannelShow == true"> 
            <td style="padding-left: 10px" >
              <p>{{ AccBooChannel.mchName }}</p>
            </td>
            <td>
              <p>{{ AccBooChannel.orgName}}</p>
            </td>
            <td>
              <p>{{ AccBooChannel.mchChannelId }}</p>
            </td>
            <td>
              <p><i class="lianjie" @click="configTab(AccBooChannel.mchChannelId,1)">配置</i></p>
            </td>                        
            <td>
              <p>
                <i
                  v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/EDIT'"
                  class="lianjie"
                  @click="editAddAccountBook(AccBooChannel)"
                  >编辑</i
                >
                <i
                  v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/DELETE'"
                  class="lianjie"
                  @click="delAddAccountBook(AccBooChannel.mchChannelId)"
                  >删除</i
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
      <h6 v-if="AccBooChannelShow != true"
        v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/ADD'"
        class="xzjsxx"
        @click="addAccountBook"
      >
        <span>+</span> 电子账簿渠道信息
      </h6>
      
      <el-dialog title="电子账簿渠道信息" :visible.sync="addAccountBookShow" width="490px">
        <ul class="overAccount_ul">
          <li class="ul_left" style="margin: 0px 24px;display: flex;flex-wrap: wrap;justify-content: space-between;width: 440px;">
            <div class="item">
              <div class="overAccount_ul_title">渠道名称</div>
              <el-input
                v-model="mchDetail.mchName"
                maxlength="128"
                placeholder="同商户名称"
                class="width100"
                disabled
                tabindex="1"
              ></el-input>
            </div>
            <div class="item">
              <div class="overAccount_ul_title"><i>*</i>所属机构</div>
              <el-select
                v-model="accountBook.orgId"
                placeholder="选择所属机构"
                class="width100"
                tabindex="2"
              >
              <el-option
                  v-for="(v, i) in organizationsLists"
                  :key="i"
                  :label="v.orgName"
                  :value="v.orgId"
                >
                </el-option>
              </el-select>
            </div>
            <div class="item">
              <div class="overAccount_ul_title"><i>*</i>渠道类型</div>
              <el-input
                maxlength="128"
                :placeholder="accountBook.mchModel==0 ? '单商户模式' : '服务商模式'"
                class="width100"
                disabled
                tabindex="2"
              ></el-input>
            </div>
            <div class="item">
              <div class="overAccount_ul_title"><i>*</i>商户号</div>
              <el-input
                v-model="accountBook.mchChannelId"
                maxlength="128"
                placeholder="输入商户号"
                class="width100"
                tabindex="5"
              ></el-input>
            </div>
            <div class="item" v-if="accountBook.mchModel==0">
              <div class="overAccount_ul_title"><i>*</i>商户RSA私钥</div>
              <el-input
                v-model="accountBook.mchPrivateKey"
                maxlength="2048"
                placeholder="输入商户RSA私钥"
                class="width100"
                tabindex="7"
              ></el-input>
            </div>
            <div class="item" v-if="accountBook.mchModel==0">
              <div class="overAccount_ul_title"><i>*</i>商户RSA公钥</div>
              <el-input
                v-model="accountBook.mchPublicKey"
                maxlength="2048"
                placeholder="输入商户RSA公钥"
                class="width100"
                tabindex="7"
              ></el-input> 
            </div> 
            <div class="item" v-if="accountBook.mchModel==0">
              <div class="overAccount_ul_title"><i>*</i>机构RSA公钥</div>
              <el-input
                v-model="accountBook.orgPublicKey"
                maxlength="2048"
                placeholder="输入机构RSA公钥"
                class="width100"
                tabindex="9"
              ></el-input>
            </div>
            <div class="item">
              <div class="overAccount_ul_title">入金回调地址</div>
              <el-input
                v-model="accountBook.notifyUrl"
                maxlength="2048"
                placeholder="输入入金回调地址"
                class="width100"
                tabindex="6"
              ></el-input>
            </div>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="addAccountBookShow = false">取 消</el-button>
          <el-button class="addBt" :disabled="buttonFlag" @click="sureAddAccountBook"
            >确 定</el-button
          >
        </span>
      </el-dialog>    

    </div>
  </div>
</template>
<script>
import {shopDetail,setPlaceInfo,setPlaceSatus,delPlaceInfo,} from "@/api/user/shopManager.js";
import { getAccountBookChannelDetail ,postAccountBookChannelAdd,delAccountBookChannel } from "@/api/trading/electron.js";
import md5 from "js-md5";
import { organizationsDrop } from "@/api/common.js";
export default {
  data() {
    return {
      infoShow: false, //新增/修改渠道基本信息展示
      formData: {
        mchChannelName: "", //true	商户渠道名称
        orgId: "", //true	所属机构ID
        channelType: "", //true	渠道类型
        settleRule: "", //true	结算规则
        mchNo: "", //true	商户号
        mchKey: "", //true 商户key
        mchPublicKey: "",
        mchPrivateKey: "",
        orgPublicKey: "",
      },
      organizationsLists: [], //支付机构列表
      tabData: [],
      editIndex: "",
      md5,
      mchDetail: "",
      oldKey: "",
      buttonFlag: false, //防止重复提交
      OFFON_EDIT:"ACCOUNT:MCH:MCHS:CHANNEL/EDIT",

      AccBooChannel:{},
      AccBooChannelShow:false,
      accountBook:{
        accountName:"",
        accountNo: "",
        aesKey: "",
        id:"",//商户Id
        mchChannelId:"",//商户号
        mchChannelName:"",//商户名称
        mchId: "",
        mchKey: "",
        mchPrivateKey: "",//商户RSA私钥
        mchPublicKey: "",//商户RSA公钥
        notifyUrl: "",//入金回调地址
        orgId: "",//所属机构
        orgPublicKey: "",//机构RSA公钥
        receiveAccountBankName:"",
        receiveAccountName: "",
        receiveAccountNo: "",
        mchModel:"",// 0就是单商户，1是服务商
      },
      addAccountBookShow:false,
      oldBookKey:"",
    };
  },
  created() {
    this.mchId = this.$route.query.id;
    this.getDetail();
    this.getOrganizationsLists();
    this.listJurisdiction();
    this.getAccountBookDetail(this.mchId);    
  },
  methods: {
    // 获取列表详情
    getDetail() {
      shopDetail(this.$route.query.id).then((res) => {
        if (res) {  
          this.tabData = res.resultData.mchChannels;
          this.mchDetail = res.resultData.mchDetail;
          console.log(this.tabData)
        }
      });
    },
    // 获取支付机构列表
    getOrganizationsLists() {
      organizationsDrop().then((res) => {
        this.organizationsLists = res.resultData;
        console.log(this.organizationsLists,222222)
      });
    },
    //获取电子账簿渠道信息
    getAccountBookDetail(mchId){
      getAccountBookChannelDetail(mchId).then((res)=>{
        if(res){
          if(res.resultData.mchChannel!=null){
            this.AccBooChannel = res.resultData.mchChannel;
            this.AccBooChannelShow = true
          }else{
            this.AccBooChannelShow = false
          }
        }       
      })
    },
    //添加电子账簿渠道信息
    addAccountBook(){
      this.accountBook = {
        accountName:"",
        accountNo: "",
        aesKey: "",
        mchChannelId:"",//商户Id
        mchChannelNo:"",//商户号
        mchChannelName:"",//商户名称
        mchId: "",
        mchKey: "",
        mchPrivateKey: "",//商户RSA私钥
        mchPublicKey: "",//商户RSA公钥
        notifyUrl: "",//入金回调地址
        orgId: "",//所属机构
        orgPublicKey: "",//机构RSA公钥
        receiveAccountBankName:"",
        receiveAccountName: "",
        receiveAccountNo: "",
        mchModel:0,
      },
      this.accountBook.mchId = this.$route.query.id;
      this.accountBook.mchModel = 0;
      this.addAccountBookShow = true;
    },
    //编辑电子账簿渠道信息
    editAddAccountBook(accountBook){
      this.accountBook = JSON.parse(JSON.stringify(accountBook));
      this.oldBookKey = accountBook.mchKey;
      console.log(this.oldBookKey,"编辑")
      this.addAccountBookShow = true;
    },
    //删除电子账簿渠道信息
    delAddAccountBook(mchChannelId){
      this.$confirm("此操作将删除该配置, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delAccountBookChannel(mchChannelId).then((res) => {
          if (res.resultStatus == true) {
              this.getAccountBookDetail(this.mchId);  
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //确认添加电子账簿渠道信息
    sureAddAccountBook() {
      let data = JSON.parse(JSON.stringify(this.accountBook));
      if (!data.orgId) {
          this.$message.error("所属机构必选");
          return false;
      }
      if (!data.mchChannelId || !/^[0-9]*$/.test(data.mchChannelId)) {
          this.$message.error("商户号必填，且为正整数");
          return false;
      }
      if(this.AccBooChannelShow == false){
        if (!data.mchPrivateKey) {
            this.$message.error("商户RSA私钥必填");
            return false;
        }
        if (!data.mchPublicKey) {
            this.$message.error("商户RSA公钥必填");
            return false;
        }
        if (!data.orgPublicKey) {
            this.$message.error("机构RSA公钥必填");
            return false;
        }
      }else{
        if (this.AccBooChannel.mchModel==0 && !data.mchPrivateKey) {
            this.$message.error("商户RSA私钥必填");
            return false;
        }
        if (this.AccBooChannel.mchModel==0 && !data.mchPublicKey) {
            this.$message.error("商户RSA公钥必填");
            return false;
        }
        if (this.AccBooChannel.mchModel==0 && !data.orgPublicKey) {
            this.$message.error("机构RSA公钥必填");
            return false;
        } 
      }
      if (data.id) {
          if (data.mchKey === this.oldBookKey) {
            delete data.mchKey;
          }
      }     
      this.buttonFlag = true;
      data.id = data.id ? data.id : 0;
      postAccountBookChannelAdd(this.$route.query.id, data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.getAccountBookDetail(this.mchId);  
          this.addAccountBookShow = false;
        }
        setTimeout(() => {
            this.buttonFlag = false;
        }, 2000);
      });
    },

    // 添加结算账户
    addTab() {
      this.formData = {
        mchChannelName: this.mchDetail.mchName,
        orgId: "",
        channelType: "",
        settleRule: "",
        mchNo: "",
        mchKey: "",
        mchPublicKey: "",
        mchPrivateKey: "",
        orgPublicKey: "",
        changeOrg:true,
      };
      this.infoShow = true;
    },
    // 修改结算账户
    editTab(formData) {
      this.formData = JSON.parse(JSON.stringify(formData));
      if(formData.mchChannelName == null){
        this.formData.mchChannelName = this.mchDetail.mchName;
      }
      this.oldKey = formData.mchKey;
      this.infoShow = true;
    },
    //删除结算账户
    delTab(id) {
      this.$confirm("此操作将删除该配置, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delPlaceInfo(this.$route.query.id, id).then((res) => {
            if (res.resultStatus == true) {
              this.getDetail();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 确认添加结算账户
    sureAdd() {
      let data = JSON.parse(JSON.stringify(this.formData));
      if (!data.orgId) {
        this.$message.error("所属机构必选");
        return false;
      }
      if (!data.channelType) {
        this.$message.error("渠道类型必选");
        return false;
      }
      if (!data.settleRule) {
        this.$message.error("结算规则必选");
        return false;
      }
      if (!data.mchNo) {
        this.$message.error("商户号必填");
        return false;
      }
      if (!data.mchKey) {
        this.$message.error("密钥必填");
        return false;
      }
      if (data.mchChannelId) {
        if (data.mchKey === this.oldKey) {
          delete data.mchKey;
        }
      }
      this.buttonFlag = true;
      data.mchChannelId = data.mchChannelId ? data.mchChannelId : 0;
      setPlaceInfo(this.$route.query.id, data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.getDetail();
          this.infoShow = false;
          setTimeout(() => {
            this.buttonFlag = false;
          }, 2000);
        }
      });
    },

    setSatus(value) {
      let data = { ...value };
      delete data.mchKey;
      setPlaceSatus(this.$route.query.id, data).then((res) => {
        if (!res) {
          value.channelStatus =
            value.channelStatus == "ACTIVATED" ? "FROZEN" : "ACTIVATED";
        }
      });
    },
    // 跳转页面
    toUrl(name, query) {
      this.$router.push({
        name: name,
        query: {
          ...query,
        },
      });
    },
    // 渠道配置
    configTab(id,type) {
      console.log(id)
      console.log(type);
      console.log("this.$route.query.id",this.$route.query.id)
      this.$router.push({
        name: "ditch_pz_edit",
        query: {
          mchId: this.$route.query.id,
          mchChannelId: id,
          checkStatus: this.mchDetail.checkStatus,
          channelType: type, // 0 渠道信息-配置 1 电子账簿渠道信息-配置
        },
      });
    },
    //判断列表按钮是否有权限
    listJurisdiction(){
        const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources;
        if(_resources=='ALL'){
            this.OFFON_EDIT = 'ACCOUNT:MCH:MCHS:CHANNEL/EDIT';
        }else{
          if(_resources.split(';').indexOf('ACCOUNT:MCH:MCHS:CHANNEL/EDIT') !== -1 ){
            this.OFFON_EDIT = 'ACCOUNT:MCH:MCHS:CHANNEL/EDIT';
          }else{
            this.OFFON_EDIT = "";
          }
        }

    },
  },
};
</script>
<style scoped>
@import "../../../assets/css/desc.css";
.tab1{
  min-height: auto !important;
}

.next {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  padding: 0;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #dce0e6;
  border-radius: 3px;
  padding: 0;
  font-size: 12px;
  color: #333333;
}

.btn {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  height: 33px;
}


.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 40px;
}


.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}
.ul_left .item{
  width: 200px !important;
}
.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>
