import axios from '@/utils/request'

// 获取商户列表-详情-渠道信息-电子账簿渠道信息，电子账簿信息，出金账户信息
export function getAccountBookChannelDetail(mchId) {
  return axios({
    url: `/manager/account/trade/${mchId}`,
    method: 'get'
  })
}

// 获取商户列表-详情-渠道信息-电子账簿渠道信息-新增账簿渠道信息/ 编辑（删除）账簿渠道信息，电子账簿信息，出金账户信息
export function postAccountBookChannelAdd(id,data) {
  return axios({
    url: `/manager/account/trade/${data.id}`,
    method: 'post',
    data
  })
}

 
//  获取商户列表-详情-渠道信息-电子账簿渠道信息-新增账簿渠道信息 -（删除）
export function delAccountBookChannel(mchChannelId,data) {
  return axios({
    url: `/manager/account/trade/${mchChannelId}`,
    method: 'delete',
    data
  })
}

// 电子账簿管理-电子账簿列表
export function getAccountBookDetail(params) {
  return axios({
    url: `/manager/account/trade/list`,
    method: 'get',
    params
  })
}

// 电子账簿管理-电子账簿列表-查询
export function getAccountBookDeSear(mchChannelId) {
  return axios({
    url: `/manager/account/trade/balance/${mchChannelId}`,
    method: 'get',
  })
}
// 电子账簿管理-电子账簿列表-状态
export function setStatus(data) {
  return axios({
    url: `/manager/account/tradeStatus/${data.mchChannelId}`,
    method: 'post',
    data
  })
}

//电子账簿管理-出金列表
export function getIncomeOrderList(params) {
  return axios({
    url:`/manager/trade/pay/incomeOrder`,
    method:'get',
    params
  })
}

//电子账簿管理-出金列表-导出
export function getIncomeOrderListExport(params) {
  return axios({
    url:`/manager/trade/pay/incomeOrder/export`,
    method:'get',
    responseType:'arraybuffer',
    params
  })
}

//电子账簿管理-出金列表
export function getOutcomeOrder(params) {
  return axios({
    url:`/manager/trade/pay/outcomeOrder`,
    method:'get',
    params
  })
}

//电子账簿管理-出金列表-导出
export function getOutcomeOrderExport(params) {
  return axios({
    url:`/manager/trade/pay/outcomeOrder/export`,
    method:'get',
    responseType:'arraybuffer',
    params
  })
}

//电子账簿管理-出金列表-同步
export function getOutcomeOrderSync(id) {
  return axios({
    url:`/manager/trade/pay/outcomeOrder/sync/${id}`,
    method:'get',
  })
}




